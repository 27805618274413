.ph-main {
    height: 250vh;
    width: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -5%;
}

.ph-text-center {
    height: 5.6%;
    margin-top: 0%;
    display: flex;
    align-items: flex-end;
    width: 50%;
}

.ph-main-text {
    width: 50%;
    word-spacing: 5px;
    line-height: 25px;
    height: 15%;
}

.ph-main-text a {
    color: var(--black);
    text-decoration: none;
    border-bottom: 3px dotted var(--black);
}

.even-space-ph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0%;
    width: 100%;
    height: 75%;
}

@media (max-width: 1000px) {
    .ph-main-text {
        width: 90%;
        font-size: 60%;
        word-spacing: 2px;
        line-height: 15px;
    }

    .ph-text-center {
        margin-top: 10%;
        width: 90%;
    }

    .ph-main {
        font-size: 80%;
        height: 160vh;
    }

    .bj-img-text2 {
        max-height: 25%;
        min-width: 90%;

    }

    .bj-img-text {
        max-height: 25%;
        min-width: 90%;
    }

    .even-space-ph {
        margin-top: 10%;
        height: 75%;
    }

}

@media (max-width: 1400px) {
    .even-space-ph{
        margin-top: 9%;
    }
    .ph-main{
        height: 340vh;
    }
    .ph-text-center{
        margin-top: 3%;
    }

}