.star {
    z-index: 0;
    position: absolute;
    background-color: var(--black);
    border-radius: 50%;
    opacity: 0.8;
    overflow: hidden;
  }
  
  @keyframes bounce {
    0% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
    50% {
      transform: translate(50px, 50px);
      opacity: 0.6;
    }
    100% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
  }

  @keyframes bounce2 {
    0% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
    50% {
      transform: translate(-50px, -50px);
      opacity: 0.6;
    }
    100% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
  }

  @keyframes bounce3 {
    0% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
    50% {
      transform: translate(-50px, 50px);
      opacity: 0.6;
    }
    100% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
  }

  @keyframes bounce4 {
    0% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
    50% {
      transform: translate(50px, -50px);
      opacity: 0.6;
    }
    100% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
  }