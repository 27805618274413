.footer {
    width: 100%;
    height: 15vh;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font);
    overflow: hidden;
    position: relative;
}

.icons {
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 350%;
}

.up {
    font-size: 70%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    border-radius: 10px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 3px solid var(--black);
    transition: 0.3s ease-in-out;

}

.middle-footer {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.up:hover {
    transform: translateY(-7%);
}

.move {
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.move:hover {
    transform: translateY(-7%);
}

#link {
    color: #0a66c2;
}

#git{
    color: var(--black);
}


@media (max-width:1400px) {
    .footer {
        height: 22vh;
    }

    .text {
        font-size: 60%;

    }
}

@media (max-width: 800px) {
    .middle-footer {
        width: 60%;
    }

    .footer {
        height: 13vh;
    }
}