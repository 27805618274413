.navbar {
    width: 100%;
    height: auto;
    position: fixed;
    background: transparent;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    -moz-backdrop-filter: blur(15px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10;
}

.text {
    display: flex;
    width: 30%;
    justify-content: space-evenly;
}

#dark {
    cursor: pointer;
    font-size: 30px;
    margin-right: 50px;
    color: var(--black);
}

#menu{
    font-size: 28px;
    color: var(--black);

}

#cross{
    font-size: 28px;
    color: var(--black);
}

.under1::after {
    content: '';
    background-color: blue;
    width: 0%;
    height: 2px;
    top: 75%;
    position: absolute;
    transition: 0.4s ease-in-out;
}

.under2::after {
    content: '';
    background-color: blue;
    width: 0%;
    height: 2px;
    top: 75%;
    position: absolute;
    transition: 0.4s ease-in-out;
}

.under3::after {
    content: '';
    background-color: blue;
    width: 0%;
    height: 2px;
    top: 75%;
    position: absolute;
    transition: 0.4s ease-in-out;
}

.under1, .under2, .under3 {
    cursor: pointer;
}

.under1 h1 {
    font-family: var(--font);
    font-size: 1.3rem;
}

.under2 h1 {
    font-family: var(--font);
    font-size: 1.3rem;
}

.under3 h1 {
    font-family: var(--font);
    font-size: 1.3rem;
}


.under1:hover::after {
    width: 65px;
    background-color: var(--black);
}

.under2:hover::after {
    width: 85px;
    background-color: var(--black);
}

.under3:hover::after{
    width: 85px;
    background-color: var(--black);
}

.mobile-look{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    width: 100px;
    height: 100px;
    top: 60px;
    left: 270px;
    -moz-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid var(--black);
    border-radius: 10px;
    background-color: var(--white-mobile);
    z-index: 20; 
}


.box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    width: 100%;
}

@media (max-width: 800px){
    .navbar{
        justify-content: space-between;


        height: 7vh;
    } 
    #menu{
        margin-right: 10%;
    } 
    #dark{
        margin-left: 25%;
    }
    #cross{
        margin-right: 10%;
    }

}

